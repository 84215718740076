header {
    position: relative;
    box-shadow: 0 3px 18px rgba(black, .06);

    .inner {
        display: flex;
        align-items: center;
    }

    .logo {
        flex: 0 0 100px;
        height: 30px;
        background-image: url('../../../public/assets/logo.gif');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        position: relative;
        z-index: 11;
        cursor: pointer;

        .skoda & {
            background-image: url('../../../public/assets/skoda-logo.svg');
            height: 36px;
        }

        .vw & {
            background-image: url('../../../public/assets/vw-logo.svg');
            flex: 0 0 30px;
        }

        .porsche & {
            background-image: url('../../../public/assets/porsche-logo.svg');
        }
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 100%;

        .navigation {
            display: flex;
            align-items: center;
            padding-left: 30px;

            @media (max-width: 991px) {
                display: block;
                padding-left: 0;

                a {
                    margin-left: 0;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;

            .identity {
                font-size: 14px;
                padding: 0 10px;
            }

            .dropdown {
                margin-left: 10px;
                z-index: 99;
            }

            @media (max-width: 991px) {
                display: block;

                .identity {
                    background-color: rgb(236, 241, 243);
                    padding: 10px 20px;
                    margin-top: 20px;
                }

                .dropdown {
                    &-current {
                        display: none;
                    }
                    &-list {
                        position: relative;
                        display: block;
                        left: 0;
                        transform: none;
                        margin-bottom: 30px;
                        
                        &-inner {
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        a {
            text-decoration: none;
            color: #000;
            padding: 4px;
            margin: 0 6px;
            position: relative;

            &.active {
                
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #000;
                }
            }
        }

        .btn {
            font-size: 14px;
            margin-left: 16px;

            &-logout {
                min-width: 0;
            }
        }

        @media (max-width: 991px) {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: 10;
            padding: 70px 0;

            a {
                display: block;
                padding: 20px;

                &.active {
                    font-weight: bold;

                    &::after {
                        display: none;
                    }
                }
            }

            .btn {
                margin-top: 20px;
                margin-left: 20px;
            }
        }
    }

    .menu-btn {
        display: none;
        width: 20px;
        height: 14px;
        -webkit-appearance: none;
        border: none;
        box-shadow: none;
        background-color: transparent;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        z-index: 11;
        
        div,
        &::after,
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: #002144;
            border-radius: 4px;
            position: absolute;
            transition: all .2s ease;
        }
        
        div {
            top: 50%;
            width: calc(100% - 6px);
            transform: translate3d(0, -50%, 0);
        }
        
        &::before {
            top: 0;
        }
        
        &::after {
            bottom: 0;
            width: calc(100% - 3px);
        }
        
        @media (max-width: 991px) {
            display: block;
        }
    }

    &.opened {

        @media (max-width: 991px) {
            nav {
                display: block;
            }
        
            .menu-btn {
                
                div {
                opacity: 0;
                transform: translate3d(100%, -50%, 0);
                }
            
                &::before {
                transform: translate3d(0, 6px, 0) rotate(45deg);
                }
            
                &::after {
                width: 100%;
                transform: translate3d(0, -6px, 0) rotate(-45deg);
                }
            }
        }
    }
}