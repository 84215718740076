.edit-user {

    &--form {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .btn {
      margin-top: 20px;
      margin-left: auto;
      font-size: 14px;
    }
}