.order-view {

  .sent-date,
  .delivery-method {
    font-size: 14px;

    span {
      &:last-child {
        font-weight: bold;
      }
    }
  }

  .view {

    &-title {
      display: block;
    }

    &-error {
      text-align: right;
    }

    &-success {
      padding-top: 60px;
      text-align: center;

      a {
        -webkit-appearance: none;
        border: none;
        background-color: var(--primary);
        border-radius: 6px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 10px 20px;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
      
        &:hover {
          background-color: var(--primarylight);
        }
      }
    }

    &-search {
      display: flex;
      position: relative;
      align-items: flex-start;

      .btn {
        font-size: 1rem;
        margin-left: 20px;

        &-search {
          margin-left: 6px;
        }
      }

      .add-error {
        margin-top: 4px;
      }

      &--results {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 18px rgba(black, .3);
        position: absolute;
        width: 100%;
        max-width: 460px;
        top: calc(100% + 20px);
        padding: 15px;
        z-index: 9;
        max-height: 600px;
        overflow-x: hidden;
        overflow-y: auto;

        &-item {
          border-bottom: 1px solid #eaeaeb;
          padding-bottom: 20px;
          margin-bottom: 20px;

          .prod-description {
            margin-top: 10px;
          }

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }

          h4 {
            margin: 0 0 20px;
          }

          h5 {
            margin: 0 0 5px;
            font-size: 14px;
          }

          &-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            @media (max-width: 991px) {
              display: block;
            }
          }

          &-actions {

            input {
              border-radius: 6px;
              text-align: center;
            }

            .btn {
              width: 100%;
              border-radius: 6px;
              margin: 10px 0 0;
              padding: 11px 20px;
              position: relative;
              height: 41px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.loading {
                background-color: #9a9c9e;
                pointer-events: none;

                .loader {

                  &::after {
                    background-color: #9a9c9e;
                  }
                }
              }

              .loader {
                margin: 0 auto;
                width: 24px;
                height: 24px;
              }
            }

            @media (max-width: 991px) {
              margin-top: 20px;
            }
          }
        }

        @media (max-width: 991px) {
          top: 50px;
          box-sizing: border-box;
        }
      }

      &--excel {
        input[type=file] {
          position: absolute;
          opacity: 0;
          pointer-events: none;
          width: 0;
        }

        &-template {
          margin-left: 20px;
          margin-top: 6px;
          font-size: 14px;
        }

        label {
          height: 40px;
          min-width: 125px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;

          &.loading {
            background-color: #9a9c9e;
            pointer-events: none;

            .loader {

              &::after {
                background-color: #9a9c9e;
              }
            }
          }

          .loader {
            margin: 0 auto;
            width: 24px;
            height: 24px;
          }
        }

        &-split {
          display: flex;
          align-items: center;
          margin-left: 20px;
          margin-top: 10px;

          input {
            order: 1;
          }

          label {
            order: 2;
            min-width: 0;
            padding: 0;
            height: auto;
            margin-left: 6px;
            font-size: 14px;
          }
        }
      }

      @media (max-width: 991px) {
        flex-wrap: wrap;

        & > .input-wrapper {
          flex: 0 0 100%;
        }

        & > .btn {
          flex: 0 0 calc(40% - 6px);
        }

        .btn-component {
          flex: 0 0 100%;
          margin-left: 0;
          margin-top: 20px;
        }

        .view-search--excel {
          flex: 0 0 100%;
          
          .btn {
            margin: 16px 0;
          }

          &-split,
          &-template {
            margin-left: 0;
          }
        }
      }
    }

    &-table {
      margin-top: 40px;

      &--wrapper {
        overflow-x: auto;
        width: 100%;
      }

      &--header {
        text-align: center;

        &-item {
          &:first-child,
          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            text-align: right;
          }

          &:nth-child(10) {
            text-align: left;
          }

          &:nth-child(11) {
            max-width: 50px;
          }
        }
      }

      &--content {

        &-item {
          &:first-child,
          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            text-align: right;
          }

          &:nth-child(10) {
            textarea {
              text-align: left;
            }
          }

          &:nth-child(11) {
            max-width: 50px;
          }

          span {
            font-size: 12px;
          }
          
          &-remove {

            span {
              display: block;
              text-align: right;
            }
          }

          .btn-remove {
            justify-content: flex-end;
            width: 100%;
            margin-left: 0;
            font-size: 10px;
            min-width: 0;
            padding-right: 5px;
          }

          input,
          textarea {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 6px;
            padding: 5px;
            text-align: center;
            background-color: #fff;

            &:focus {
              outline: 0;
            }

            &:disabled {
              background-color: transparent;
              border: none;
              padding: 0;
            }
          }

          textarea {
            max-height: 25px;
            resize: none;
          }
        }
      }

      &--footer {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        .view-table--actions {
          display: flex;

          button {

            &.btn-component {
              font-size: 16px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }

        @media (max-width: 768px) {
          display: block;

          .view-table--actions {
            margin-top: 30px;
          }
        }
      }

      &--sum {
        margin-bottom: 10px;
      }
    }
  }

  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}