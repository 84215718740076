.users-view {

  .view {
    &-table {

      &--header,
      &--content {
        &-item {
          &-name {
            text-align: left;
          }
          &-actions {
            text-align: right;
          }
          &-actions {
            text-align: right;

            .btn-small {
              margin: 0 4px;
              padding: 4px;
              background-color: transparent;

              svg {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  &--add {
    &-form {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .btn {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}