.orders-view {

  .view {
    &-table {

      &--header,
      &--content {
        &-item {
          &-id {
            text-align: left;
          }
          &-status {
            text-align: right;
          }
        }
      }

      &--header {
        cursor: pointer;
        user-select: none;

        &-sort {

          &-asc,
          &-desc {
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
              justify-content: flex-start;
            }

            &:last-child {
              justify-content: flex-end;
            }

            &::after {
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              background-image: url('../../../public/assets/chevron.svg');
              background-size: contain;
              margin-left: 10px;
            }
          }

          &-desc {
            
            &::after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}