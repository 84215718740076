.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 30px;

  &-prev,
  &-next {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      color: lighten(black, 20);
    }
  }

  &-pages {
    background-color: #f6f8fa;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 14px;
    pointer-events: none;
  }

  .disabled {
    opacity: 0;
    pointer-events: none;
  }
}