.invoices-view {

  .view {
    &-table {

      &--header,
      &--content {
        &-item {
          &-invoiceId {
            text-align: left;
          }
          &-link {
            text-align: right;
          }
        }
      }
    }
  }
}