footer {
    position: relative;
    background-color: #000;
    color: #fff;
    box-shadow: 0 3px 18px rgba(black, .06);

    .skoda & {
        background-color: var(--primary);
    }

    .vw & {
        background-color: #00205a;
    }

    .inner {
        display: flex;
        align-items: center;
    }

    .logo {
        flex: 0 0 100px;
        height: 30px;
        background-image: url('../../../public/assets/logo.gif');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        z-index: 8;

        .skoda & {
            background-image: url('../../../public/assets/skoda-logo-white.svg');
        }

        .porsche & {
            background-image: url('../../../public/assets/porsche-logo.svg');
        }

        .vw & {
            background-image: url('../../../public/assets/vw-logo-white.svg');
        }
    }

    .footer--contact-info {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        a {
            color: #fff;
        }

        & > * {
            font-size: 14px;
            margin: 0 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 768px) {
        .inner {
            display: block;

            .logo {
                background-position: 50%;
                margin-bottom: 30px;
            }

            .footer--contact-info {
                display: block;
                text-align: center;

                & > * {
                    margin: 10px 0;
                }
            }
        }
    }
}