.btn {
  -webkit-appearance: none;
  border: none;
  background-color: var(--primary);
  border-radius: 6px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 10px 20px;
  display: block;
  cursor: pointer;
  min-width: 140px;

  &:hover {
    background-color: var(--primarylight);
  }

  &-small {
    min-width: 0;
    display: inline-block;
    padding: 6px 10px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 100%;
      height: 100%;
      max-width: 14px;
      fill: #fff;
    }
  }

  &--has-icon {
    display: flex;
    align-items: center;

    .btn--icon {
      & + span {
        font-size: 0.8rem;
      }
    }
  }
}

a {
  color: #000;
  
  &:hover {
    color: lighten(#000, 10);
  }

  &.pdf-download {
    cursor: pointer;
    &.loading {
      pointer-events: none;
    }
  }
}

.inner {
  max-width: 1420px;
  margin: 0 auto;
  padding: 20px;
}

.input-wrapper {
  
  input {
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(black, .2);
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 1rem;
    box-sizing: border-box;

    &:focus {
      outline: 0;
    }
  }

  &--label {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  textarea {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 12px;
    text-align: left;
    border-radius: 6px;
    resize: vertical;

    &:focus {
      outline: 0;
    }
  }

  &--error {
    color: rgb(167, 2, 2);
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    
    input {
      border-color: var(--primary);
    }
  }
}

.dropdown {
  position: relative;

  &-current {
    border-radius: 10px;
    padding: 4px 10px;
    background-color: hsl(210, 29%, 97%);
    cursor: pointer;
    font-size: 14px;
  }

  &-list {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    padding-top: 10px;
    min-width: 100px;

    &-inner {
      background-color: #fff;
      box-shadow: 0 3px 14px rgba(black, .2);
      border-radius: 10px;
      padding: 6px 0;
    }

    &-item {
      padding: 6px 10px;
      border-bottom: 1px solid #f6f8fa;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: #f6f8fa;
      }
    }
  }

  &:hover {
    .dropdown-list {
      display: block;
    }
  }
}

.input-error {
  font-size: 12px;
  color: rgb(167, 2, 2);
  padding: 8px 0 0 3px;
}

.view {
  min-height: 100vh;
  max-width: 100vw;

  &-title {
    display: flex;
    align-items: center;

    &--inner {
      position: relative;
      display: flex;
      align-items: center;

      .loader {
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        margin: 0.67em 0 0 20px;
      }
    }

    .btn {
      margin-left: 60px;
      font-size: 14px;
    }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 20px;

      h1 {
        margin-bottom: 10px;
      }
      .btn {
        display: block;
        margin: 0;
      }
    }
  }

  &-table {
    overflow-x: scroll;
      
    &--header {
      display: grid;
      gap: 10px;

      &-item {
        font-size: 12px;
        color: rgba(black, .7);
        font-weight: bold;
        padding: 10px 4px;
        text-align: center;
      }
    }

    &--content {
      display: grid;
      padding-bottom: 20px;

      &-row {
        display: grid;
        gap: 10px;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: #f6f8fa;
        }

        &:hover {
          background-color: darken(#f6f8fa, 3);
        }
      }

      &-item {
        padding: 14px 4px;
        font-size: 14px;
        text-align: center;
        word-wrap: break-word;

        &.even {
          background-color: #f6f8fa;
        }

        .btn-remove {
          font-size: 12px;
          padding: 0;
          display: flex;
          background-color: transparent;
          color: var(--primary);
          font-weight: 600;

          &:hover {
            color: var(--primarylight);
          }
        }

        &.remove {
          .btn {
            margin-left: auto;
          }
        }

        &-error {
          background-color: rgba(red, .2) !important;
        }
      }
    }

    &--footer {
      margin-top: 30px;
      border-top: 1px solid rgba(black, .2);
    }
  }

  &-error {
    color: rgb(167, 2, 2);
    font-weight: bold;
  }
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  background: -moz-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 .6s infinite linear;
  animation: load3 .6s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &::before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &::after {
    background: var(--primary);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &-dark {
    background: #fff;
    background: -moz-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 42%);

    &::before {
      background: var(--primary);
    }

    &::after {
      background: #fff;
    }
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.delivery-modal {
  .btn {
    font-size: 16px;
    margin-left: auto;
    margin-top: 20px;
    height: 46px;
  }
}

.delivery-methods {
  background-color: #f6f8fa;
  padding: 16px;
  border-radius: 10px;
  .delivery-method {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    span,
    input {
      margin-left: 14px;
    }

    .selection {
      width: 14px;
      height: 14px;
      border: 2px solid #656668;
      border-radius: 50px;
      position: relative;
      background-color: #fff;
    }

    &--input {
      & > span {
        display: block;
        margin-bottom: 6px;
      }
    }

    &.selected {
      .selection {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: var(--primary);
          border-radius: 20px;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

table {

  &.view-table {
    width: 100%;

    &--content {
      display: initial;
    }
  }
}

.language-switch {
  width: auto;
  display: inline-block;
  margin: 0 auto 10px;
  min-width: 120px;

  .dropdown-current {
    background-color: #000;
    color: #fff;
    border-radius: 6px;
    padding: 6px 10px;
  }
}

#confirm-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;
  padding: 20px;

  .modal {

      &-background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#BDBEC2, .3);
          pointer-events: all;
          z-index: 1;
      }

      &-inner {
          background-color: #fff;
          position: relative;
          z-index: 2;
          padding: 20px 0;
          border-radius: 10px;
          width: 100%;
          height: auto;
          max-width: 440px;
          pointer-events: all;
          max-height: calc(100vh - 40px);
          box-shadow: 0 3px 16px rgba(black, .08);
      }

      &-header {
          padding: 0 20px;
      }

      &-content {
          overflow-y: scroll;
          height: 100%;
          max-height: calc(100vh - 200px);
          padding: 0 20px;
      }

      &-title {
          margin: 0 0 20px 0;
          font-size: 16px;
      }

      &-close {
          width: 20px;
          height: 20px;
          position: absolute;
          -webkit-appearance: none;
          background: none;
          border: none;
          box-shadow: none;
          top: 20px;
          right: 20px;
          cursor: pointer;
      
          &::after,
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--primary);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
          }
      
          &::before {
            transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          }
      
          &:hover {
            opacity: .6;
          }
      }

      &-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 20px 0;
      }
  }
}