.btn-component {

  &:hover {
    background-color: var(--lightred);

    &:disabled {
      background-color: #9a9c9e;
    }
  }

  &.btn-secondary {
    background-color: #000;
    color: #fff;

    &:hover {
      background-color: lighten(#000, 20);
    }
  }

  &:disabled {
    background-color: #9a9c9e;
    cursor: unset;

    &:hover {
      background-color: #9a9c9e;
    }
  }

  &.loading {
    background-color: #9a9c9e;
    pointer-events: none;

    .loader {

      &::after {
        background-color: #9a9c9e;
      }
    }
  }

  .loader {
    margin: 0 auto;
    width: 20px;
    height: 20px;
  }
}