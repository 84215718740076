.login-view {
  background-color: #f0f0f1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;

  &--logo {
    width: 100px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../public/assets/logo.gif');
    background-position: 50%;
    margin: 0 auto;

    .skoda & {
      background-image: url('../../../public/assets/skoda-logo.svg');
    }

    .vw & {
      background-image: url('../../../public/assets/vw-logo.svg');
      height: 40px;
    }

    .porsche & {
      background-image: url('../../../public/assets/porsche-logo.svg');
    }
  }

  &--inner {
    background-color: #fff;
    border-radius: 32px;
    box-shadow: 0 3px 18px rgba(black, .03);
    padding: 40px;
    max-width: 500px;
    width: 100%;
    text-align: center;

    form {
      display: grid;
      gap: 10px;
      max-width: 300px;
      margin: 0 auto;
    }
  }
}