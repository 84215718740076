#modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 13;

  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .4);
    pointer-events: all;
  }

  .modal-inner {
    position: relative;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    flex: 0 1 560px;
    box-shadow: 0 3px 24px rgba(black, .2);
    pointer-events: all;

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .btn {
        margin: 0;
      }
    }
  }

  .modal-close {
    width: 20px;
    height: 20px;
    position: absolute;
    -webkit-appearance: none;
    background: none;
    border: none;
    box-shadow: none;
    top: 20px;
    right: 20px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &::before {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }

    &:hover {
      opacity: .6;
    }
  }
}