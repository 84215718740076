.dealerships-view {

  .view {
    &-table {

      &--header,
      &--content {
        &-item {
          &-brands {
            text-align: center;
          }
          &-name {
            text-align: left;
          }
          &-actions {
            text-align: right;

            .btn-small {
              margin: 0 4px;
              padding: 4px;
              background-color: transparent;

              svg {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  &--add {

    &-form {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .btn {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}

.add-error {
  margin-top: 16px;
  color: rgb(167, 2, 2);
  font-size: 14px;
}

.add-success {
  margin-top: 16px;
  color: #0bc05c;
  font-size: 14px;
}

.users-list-view {

  &--user {
    padding: 8px 5px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-info,
    &-actions {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    &-info {
      a {
        text-decoration: underline;
      }
    }

    &-fields {
      margin: 10px 0;
    }

    &-actions {
      .btn {
        font-size: 0.8rem;
      }
    }

    &:nth-child(odd) {
      background-color: #f6f8fa;
    }

    span {
      display: block;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    &:hover {
      background-color: #ecf0f5;
    }
  }
}